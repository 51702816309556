.loader {
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }
  .circle {
    width: 70px;
    height: 70px;
    border: 2px solid rgb(0, 225, 255);
    border-radius: 100%;
    border-bottom-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rotate {
    animation: rotation 1.2s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }