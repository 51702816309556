.portalContainer {  
  width: 80%;
  height: 80%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5000;
}
.overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 4000;
}
@media screen and (max-width: 400px) {
  .portalContainer{
    width: 95%;
  }
}