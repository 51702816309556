/* global reset */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

.backgroundHero{
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}
.backgroundHero::before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
}
.backgroundHero > img{
  height: 100%;
  width: 100%;
}

/* Scroll Bar start */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Scroll Bar start ends */

section {
  /*TOP      left*/
  /*bottom   right*/
  padding: 3.9rem 0;
  overflow-x: hidden;
}

header {
  width: 100%;
  padding: 1.5rem 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1500;
}
img {
  width: 100%;
  max-width: 100%;
}

a {
  text-decoration: none;
}
li {
  list-style: none;
}

.container {
  width: 100%;
  max-width: 135rem;
  margin: 0 auto;
  padding: 0 2.4rem;
}
/* Hero starts */
.hero {
  width: 100%;
  height: 100vh;
  min-height: 600px;
  background: center no-repeat fixed;
  background-size: cover;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1000;
}
/* Hero ends */

.nav {
  height: 14rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 15rem;
  margin: 1rem 0;
}
.logo img {
  cursor: pointer;
}

.menu-toggle {
  display: none;
}
.menu-toggle {
  margin: 1rem 0;
  position: absolute;
  right: 6.2rem;
  cursor: pointer;
  z-index: 1700;
}
/*****************/

.list-items {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.link {
  margin: 0 1rem;
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  color: rgb(197, 197, 197);
  cursor: pointer;
}

.link:hover {
  color: #ffb979;
  transition: color 0.3s;
}
/* Nav-bar ends */

.greet {
  font-family: "Sulphur Point", sans-serif;
  font-size: 3rem;
  letter-spacing: 0.5rem;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.6);
}
/* animation classes for intro*/
.mov-down {
  opacity: 0;
  margin-bottom: 2rem;
  animation: moveInDown 1s ease-out forwards;
}
.fade {
  opacity: 0;
  transform-origin: center;
  animation: fadeIn 1.5s ease-out 0.3s forwards;
}
.mov-up {
  opacity: 0;
  animation: moveInUp 1.2s ease-out 0.8s forwards;
}
.name {
  font-family: "Allura", cursive;
  font-size: 7.5rem;
  letter-spacing: 0.4rem;
  margin-bottom: 1rem;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: #9dfeff;
  background-image: linear-gradient(
    90deg,
    #30b0c0 0%,
    #ffffff 45%,
    #ff1500 82%
  );
}
.small-about {
  font-family: "Sulphur Point", sans-serif;
  font-size: 3rem;
  letter-spacing: 0.15rem;
  color: rgba(255, 255, 255, 0.5);
}
.scrollDownArrowContainer {
  width: 14rem;
  height: 2px;
  border-bottom: 1px solid #d5c7c7;
  position: absolute;
  left: 50%;
  bottom: 14rem;
  transform: translateX(-50%);
  opacity: 0;
  animation: bounceBase 0.8s 2s forwards;
}
.scrollDownArrow {
  font-size: 5rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -0.4rem;
  color: #d5c7c7;
  opacity: 0;
  animation: bounce 0.8s 2s infinite forwards;
}
.scrollDownMsg {
  padding: 1rem 0;
  font-family: "Sulphur Point", sans-serif;
  font-size: 2rem;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.5);
}
/* Intro ends */

.skills {
  text-align: center;
  background-color: rgba(51, 51, 51, 0.6);
}

.heading {
  opacity: 0;
  font-family: "Roboto", sans-serif;
  font-size: 7rem;
  margin-top: 1.5rem;
  color: #b69360;
}
.line {
  opacity: 0;
  display: inline-block;
  width: 100%;
  max-width: 18rem;
  border-bottom: #c59d5f solid 2px;
}
.skills-name-and-logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
  margin-top: 4.5rem;
  margin-bottom: 1.5rem;
}
.skill-container {
  color: rgb(216, 216, 216);
}
.logr {
  margin-bottom: 0.5rem;
  width: 12rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid #6d85ab;
  box-shadow: 0 0 5px #6d85ab;
  background-color: rgba(0, 0, 0, 0.5);
}
.skill-logo {
  width: 7rem;
  height: 6rem;
}
.skill-container {
  opacity: 0;
  display: flex;
  flex-direction: column;
  font-family: "Sulphur Point", sans-serif;
  font-size: 2rem;
  letter-spacing: 0.15rem;
}
/* skill section end */

.projects {
  position: relative;
  text-align: center;
}
.project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.card {
  opacity: 0;
  width: 50rem;
  height: 40rem;
  padding: 2rem;
  margin: 6rem 2rem 3rem;
  position: relative;
  border: 1px solid #6d85ab;
  box-shadow: 0 0 5px #6d85ab;
  border-radius: 25px;
  background: rgba(32, 52, 58, 0.2);
  overflow: hidden;
}
.card-elements {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}
.card-left-part {
  width: 50%;
  border-right: rgba(255, 255, 255, 0.2) 1px solid;
}
.card-right-part {
  width: 50%;
}
.pr-pad {
  padding: 1rem;
}
.heads {
  font-family: "Roboto", sans-serif;
  font-size: 2.5rem;
  font-weight: 100;
  letter-spacing: 0.15rem;
  color: rgb(236, 226, 226);
}
.pr-heads {
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: rgba(255, 255, 255, 0.2) 1px solid;
}
.pr-thumb {
  height: 54%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem 1rem;
  border-bottom: rgba(255, 255, 255, 0.2) 1px solid;
  cursor: pointer;
}
.pr-thumb > a > img {
  width: 16rem;
  height: 14rem;
  border-radius: 10px;
}
.used-techs {
  height: 24%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}
h4 {
  font-family: "Sulphur Point", sans-serif;
  font-size: 1.5rem;
  margin: 0 1rem;
  letter-spacing: 0.15rem;
  color: rgb(175, 169, 169);
}
.pr-description {
  height: 54%;
  padding-top: 2rem;
  font-family: "Sulphur Point", sans-serif;
  font-size: 1.5rem;
  letter-spacing: 0.15rem;
  border-bottom: rgba(255, 255, 255, 0.2) 1px solid;
  color: rgb(175, 169, 169);
}
.btn-container {
  height: 24%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
  margin: auto 0;
}
.btn {
  width: 9rem;
  height: 3.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #fff 1px solid;
  border-radius: 0.6rem;
  background-image: linear-gradient(
    to right,
    #556270 40%,
    rgb(184, 90, 90) 100%
  );
}
.btn:hover {
  border-color: #c9a570;
  color: #cab391;
  background: transparent;
}
.btn-name {
  font-family: "Roboto", sans-serif;
  font-size: 1.1rem;
  letter-spacing: 0.15rem;
  color: rgba(0, 0, 0, 0.9);
}
.btn-modal {
  font-family: "Roboto", sans-serif;
  font-size: 1.8rem;
  font-weight: 900;
  letter-spacing: 0.15rem;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}
/* project section end */

.about {
  text-align: center;
  background-color: rgba(51,51,51,.6);
}
.abt-content {
  margin-top: 4.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.abt-left-part {
  display: flex;
  justify-content: center;
}
.profile-pic {
  width: 21rem;
  height: 21rem;
  box-shadow: 0 0 1px 4px rgb(98, 201, 166);
  border-radius: 100%;
}
.abt-left-part {
  opacity: 0;
  width: 30%;
}
.abt-right-part {
  opacity: 0;
  width: 55%;
}
.the-abt {
  font-family: "Sulphur Point", sans-serif;
  font-size: 2.2rem;
  letter-spacing: 0.15rem;
  padding: 0 2rem;
  color: #c9c9c9;
}
/* About section end */

.contact {
  text-align: center;
}
.contact-content {
  margin-top: 4.5rem;
  display: flex;
  justify-content: space-between;
}
.con-left-part {
  opacity: 0;
  width: 40%;
}
.con-right-part {
  opacity: 0;
  width: 40%;
}
.con-head {
  margin-bottom: 2rem;
}

.social-icons-container {
  display: flex;
  justify-content: center;
}
.social-icons-container > li {
  margin: 0 1.5rem;
}
.con-loader {
  padding: 2rem 0;
  position: relative;
}
.con-btn-icon {
  display: block;
}
.con-btn {
  padding: 0.6rem 0;
  background: transparent;
  border: none;
  font-size: 2.8rem;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.con-btn:hover {
  color: #c9a570;
}

.msg-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.msg-input {
  width: 35rem;
  height: 3rem;
  display: inline-block;
  margin: 1rem;
  text-align: center;
}
.text-area {
  width: 35rem;
  margin: 1rem;
  text-align: center;
}
/* Contact section end */

footer {
  padding: 2rem 0;
  text-align: center;
  overflow: hidden;
}
.copyright-msg {
  font-family: "Sulphur Point", sans-serif;
  font-size: 2rem;
  letter-spacing: 0.15rem;
  color: rgb(184, 184, 184);
}

/* footer end  */

/* Media Queries */
@media screen and (min-width: 1600px) {
  html {
    font-size: 13px;
  }
}
@media screen and (min-width: 1800px) {
  html {
    font-size: 14px;
  }
}
/***********************************/

@media screen and (max-width: 900px) {
  html {
    font-size: 9px;
  }
  .backgroundHero > img {
    object-fit: cover;
    object-position: center;
  }
}
@media screen and (max-width: 825px) {
  html {
    font-size: 8px;
  }
}
@media screen and (max-width: 700px) {
  .con-right-part {
    width: 50%;
  }
  .profile-pic{
    width: 18rem;
    height: 18rem;
  }
  .abt-left-part{
    width: 50%;
  }
  .abt-right-part{
    width: 50%;
  }
}
@media screen and (max-width: 630px) {
  html {
    font-size: 7px;
  }
  .contact-content {
    flex-direction: column-reverse;
    align-items: center;
  }
  .con-right-part {
    margin-bottom: 8rem;
  }
  .con-left-part {
    margin-bottom: 1rem;
    width: 35rem;
  }
  .con-loader {
    padding: 2.5rem 0;
  }
  .con-btn {
    padding: 1.1rem 0;
  }

  /****************/
  .main-logo {
    width: 20rem;
    transform: translateX(8%);
  }
  .nav {
    justify-content: center;
  }

  .list-items {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 4.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transform: translateX(-100%);
    transition: transform 0.5s ease-out;
  }
  .open {
    transform: translateX(0);
  }

  .menu-toggle {
    display: block;
    position: fixed;
  }
  .menu {
    width: 40px;
    height: 25px;
  }
  span {
    display: inline-block;
    width: 50%;
    height: 1.5px;
    border-radius: 100px;
    background-color: #945555;
    position: absolute;
    top: 50%;
    right: 0;
    transform-origin: center;
    transition: 0.5s all cubic-bezier(0.23, 1.23, 0.59, 1.32);
  }
  .menu span:first-child {
    top: 0%;
    width: 100%;
  }
  .menu span:last-child {
    top: 100%;
    width: 75%;
  }

  .active > span {
    opacity: 0;
    background-image: none;
    background-color: rgb(255, 0, 0);
  }
  .active > span:first-child {
    opacity: 1;
    top: 50%;
    right: 0;
    width: 100%;
    transform: rotateZ(-45deg) translateY(-50%);
  }
  .active > span:last-child {
    opacity: 1;
    top: 50%;
    right: 0;
    width: 100%;
    transform: rotateZ(45deg) translateY(-50%);
  }

  .link {
    display: block;
    font-size: 2.4rem;
    margin-right: -0.15px;
  }

  .pr-description {
    font-size: 1.8rem;
    overflow-y: auto;
  }
}
@media screen and (max-width: 475px) {
  html {
    font-size: 6.5px;
  }
  .main-logo {
    width: 18rem;
  }
  .name {
    font-size: 6.5rem;
  }
}
@media screen and (max-width: 350px) {
  .card {
    height: 48rem;
  }
  .pr-thumb {
    height: 50%;
  }
  .used-techs {
    height: 30%;
  }
  .pr-description {
    height: 50%;
  }
  .btn-container {
    height: 30%;
  }
}

/* Animations */
@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes moveInDown {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes moveInUp {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes bounce {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
@keyframes bounceBase {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes leftReveal {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes rightReveal {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
