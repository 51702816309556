.projPicsContainer {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0 2rem;
}
.modalImg {
  width: 90%;
  max-height: 95%;
}
.btn-close-modal {
  padding: 0.5rem;
  font-size: 3rem;
  border-radius: 100%;
  border: 2px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.btn-close-modal:hover {
  border-color: red;
  color: red;
}
.btn-modal-action {
  font-size: 3.5rem;
  color: #fff;
  cursor: pointer;
}
.btn-modal-action:hover {
  color: #0064ff;
}
@media screen and (max-width: 700px) {
  .btn-close-modal {
    border-color: red;
    color: red;
  }
  .btn-modal-action {
    font-size: 3rem;
  }
}
@media screen and (max-width: 550px) {
  .modalImg{
    width: 85%;
  }
}
@media screen and (max-width: 460px) {
  .projPicsContainer{
    gap: 0 1rem;
  }
  .modalImg{
    width: 80%;
  }
  .btn-modal-action{
    font-size: 3.5rem;
  }
}
