.loader2 {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 3.5px solid rgb(0, 225, 255);
  border-bottom-color: transparent;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rotate2 {
  animation: rotation2 .8s infinite linear;
}
@keyframes rotation2 {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
